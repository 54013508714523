import React, { useState, useEffect } from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectRnD } from "../../reducers/performanceDataSlice";
import { selectEconRnD } from "../../reducers/econDataSlice";
import { motion } from "framer-motion";

const RoutesAndDistances = (props) => {
	const dispatch = useDispatch();
	let packageType = useSelector((state) => state.packageType.value);
	let packageLevel = useSelector((state) => state.packageLevel.value);
	let econData = useSelector((state) => state.econData);
	let perfData = useSelector((state) => state.performanceData);
	let data;
	if (packageType === "Performance") {
		data = perfData;
	} else {
		data = econData;
	}

	let apCode = data.RnD.iataAirportCode;
	let inputChoice = data.acDefault;
	let gcdInputs = data.RnD.gcd;
	let cDistance = data.RnD.customDist;
	let distanceUnit = data.RnD.distanceUnit;

	let RnDInputs = {
		distanceUnit: "",
		iataAirportCode: [[], []],
		gcd: [],
		customDist: [[], []],
	};

	const [custom, setCustom] = useState();
	const chooseCustom = (type) => {
		setCustom(type);
	};

	const [, setFirst] = useState(apCode);
	const [, setSecond] = useState();
	const [, setThird] = useState();

	const [defaultapCodes, setdefaultapCodes] = useState(
		apCode === undefined || apCode.length === 0 ? false : true
	);
	const [defaultDistance, setDefaultDistance] = useState(
		cDistance === undefined || cDistance.length === 0 ? false : true
	);
	const [defaultGCD, setDefaultGCD] = useState(
		gcdInputs === undefined || gcdInputs.length === 0 ? false : true
	);

	const [defaultUnit, setDefaultUnit] = useState(
		distanceUnit === undefined ? false : true
	);

	const [gcdArr, setGcdArr] = useState([]);
	const secondTable = (type, index) => {
		setGcdArr([...gcdArr, [type, index]]);
		setSecond(type);
	};

	// check screen width
	var windowWidth = window.innerWidth;
	const [windowLarge, setWindowLarge] = useState(windowWidth >= 1024);
	window.onresize = handleResize;
	function handleResize() {
		windowWidth = window.innerWidth;
		if (windowWidth < 1024) {
			setWindowLarge(false);
		} else if (windowWidth <= 1441) {
			setWindowLarge(true);
		} else {
			setWindowLarge(true);
		}
	}

	// preload the values
	useEffect(() => {
		if (defaultGCD) {
			gcdInputs.forEach((value) => {
				gcdArr.push(value);
			});
		}
	}, []);

	let dropDownNodes = document.querySelectorAll(
		'div[data-type="select"] > select'
	);
	let inputNodes = document.querySelectorAll(
		'div[data-type="input"] > div > div > div > input'
	);

	let dropDownRoutes = Array.from(dropDownNodes).filter((elem, index) => {
		return index % 2 == 0;
	});
	let dropDownDiversion = Array.from(dropDownNodes).filter((elem, index) => {
		return (index + 1) % 2 == 0;
	});
	let inputRoutes = Array.from([...inputNodes])
		.slice(packageLevel === "Basic" ? 30 : 60)
		.filter((elem, index) => {
			return index % 2 == 0;
		});
	let inputDiversion = Array.from([...inputNodes])
		.slice(packageLevel === "Basic" ? 30 : 60)
		.filter((elem, index) => {
			return (index + 1) % 2 == 0;
		});

	function addRouteDistanceEventListeners(
		gcdRoute,
		gcdDiversion,
		distanceRoute,
		distanceDiversion,
		index
	) {
		function clearGcdRoute() {
			gcdRoute.value = "Route";
			cleanGcdArr([index, 0]);
		}
		function clearGcdDiversion() {
			gcdDiversion.value = "Diversion";
			cleanGcdArr([index, 1]);
		}

		// need to call it this way bc of how react handles controlled vs uncontrolled
		// forms -> it forces a rerender of the input value
		function clearDistanceRoute() {
			Object.getOwnPropertyDescriptor(
				window.HTMLInputElement.prototype,
				"value"
			).set.call(distanceRoute, "");
			distanceRoute.dispatchEvent(new Event("input", { bubbles: true }));
		}
		function clearDistanceDiversion() {
			Object.getOwnPropertyDescriptor(
				window.HTMLInputElement.prototype,
				"value"
			).set.call(distanceDiversion, "");
			distanceDiversion.dispatchEvent(new Event("input", { bubbles: true }));
		}
		function cleanGcdArr(index) {
			gcdArr.forEach((value, innerIndex) => {
				if (value[1][0] === index[0] && value[1][1] === index[1]) {
					gcdArr.splice(innerIndex, 1);
				}
			});
		}

		try {
			gcdRoute.addEventListener("change", clearDistanceRoute);
			gcdDiversion.addEventListener("change", clearDistanceDiversion);
			distanceRoute.addEventListener("change", clearGcdRoute);
			distanceDiversion.addEventListener("change", clearGcdDiversion);
		} catch {}
	}

	dropDownRoutes.map((item, index) => {
		addRouteDistanceEventListeners(
			item,
			dropDownDiversion[index],
			inputRoutes[index],
			inputDiversion[index],
			index
		);
	});

	const processTable = (nodes, chunk) => {
		let result = [];
		let currentRow = [];
		nodes.forEach((element, index) => {
			currentRow.push(element.value);
			if ((index + 1) % chunk === 0) {
				result.push(currentRow);
				currentRow = [];
			}

			return element.value;
		});
		return result;
	};

	const dispatchInputs = () => {
		const apArr = processTable(
			Array.from([...inputNodes]).slice(0, packageLevel === "Basic" ? 30 : 60),
			3
		);
		const cdArr = processTable(
			Array.from([...inputNodes]).slice(packageLevel === "Basic" ? 30 : 60),
			2
		);
		RnDInputs.iataAirportCode = apArr;
		let capitalizedRoutes = RnDInputs.iataAirportCode.map((subArray) =>
			subArray.map((item) => item.toUpperCase())
		);
		RnDInputs.iataAirportCode = capitalizedRoutes;
		RnDInputs.gcd = gcdArr;
		RnDInputs.customDist = cdArr;
		RnDInputs.distanceUnit = custom;
		if (packageType === "Performance") {
			dispatch(selectRnD(RnDInputs));
		} else if (packageType === "Economic") {
			dispatch(selectEconRnD(RnDInputs));
		}
	};

	const validateContinue = () => {
		let validated = true;
		let iataInputs = Array.from([...inputNodes]).slice(
			0,
			packageLevel === "Basic" ? 30 : 60
		);

		let distanceInputs = Array.from([...inputNodes]).slice(
			packageLevel === "Basic" ? 30 : 60,
			packageLevel === "Basic" ? 60 : 100
		);
		let dropdownInputs = Array.from([...dropDownNodes]);

		let increment = 0;
		const invalidInputs = [];
		const validMatrix = [];
		let currentRow = [];
		iataInputs.forEach((input, index) => {
			const rowIndex = Math.floor(index / 3);
			// this checks for invalid iata codes
			if (input.value.length !== 3 && input.value !== "") {
				invalidInputs.push(input.value.toUpperCase());
			}
			currentRow.push(input.value);
			increment++;
			if (increment === 3) {
				const currentDropdownRow = dropdownInputs.slice(
					rowIndex * 2,
					rowIndex * 2 + 2
				);
				const currentDistanceRow = distanceInputs.slice(
					rowIndex * 2,
					rowIndex * 2 + 2
				);

				if (currentRow.every((element) => element.trim().length === 3)) {
					if (
						(currentDropdownRow[0].value.trim() !== "Route" ||
							currentDistanceRow[0].value.trim() !== "") &&
						(currentDropdownRow[1].value.trim() !== "Diversion" ||
							currentDistanceRow[1].value.trim() !== "")
					) {
						validMatrix.push(true);
					} else {
						validMatrix.push(false);
					}
				} else if (!currentRow.every((element) => element === "")) {
					validMatrix.push(false);
				} else if (currentRow.every((element) => element === "")) {
					if (
						currentDropdownRow[0].value.trim() !== "Route" ||
						currentDistanceRow[0].value.trim() !== "" ||
						currentDropdownRow[1].value.trim() !== "Diversion" ||
						currentDistanceRow[1].value.trim() !== ""
					) {
						validMatrix.push(false);
					}
				}
				currentRow = [];
				increment = 0;
			}
		});
		if (iataInputs.every((element) => element.value.length === 0)) {
			validated = false;
		}
		if (invalidInputs.length > 0) {
			validated = false;
		}
		if (validMatrix.includes(false)) {
			validated = false;
		}
		return [validated, invalidInputs];
	};

	return (
		<motion.div>
			<UtilityClass.CustomText
				textSize="subtitle"
				fontWeight="semibold"
				color="black"
				content={"Routes and Distances"}
			/>
			<UtilityClass.CustomText
				textSize="large"
				fontWeight="semibold"
				color="black"
				content={`Input up to ${
					packageLevel === "Basic" ? "10" : "20"
				} routes below, using valid IATA codes`}
			/>
			<UtilityClass.RadioButtons
				title="Select distance unit for reporting"
				text={["Nautical Miles", "Kilometres", "Statute Miles"]}
				value={chooseCustom}
				defaultValue={defaultUnit ? distanceUnit : "Nautical Miles"}
				width="fit"
			/>
			<UtilityClass.CustomText
				textSize="large"
				fontWeight="semibold"
				color="black"
				content={
					"For each route and diversion distance, you have the option to:\n- select an airways allowance factor that will be applied to the Great Circle Distance (GCD)\nor\n- enter a custom distance"
				}
			/>
			<UtilityClass.Separator />
			<div className="lg:flex justify-center w-full">
				<div className="flex justify-center lg:w-1/4 md:1/3 sm:full">
					<UtilityClass.InputTable
						autoComplete={true}
						indexBool={true}
						rowNum={packageLevel === "Basic" ? 10 : 20}
						colNum={3}
						checkboxSmallerBottomMarginBool={true}
						checkboxLabelBottomMarginBool={true}
						captionBool={true}
						label="IATA Airport Codes"
						placeholder={["From", "To", "Diversion"]}
						showIndex={Array(packageLevel === "Basic" ? 10 : 20).fill(true)}
						value={setFirst}
						includeDefaults={defaultapCodes}
						capitalize={true}
						defaultVals={apCode}
						width="full"
						toolTip={true}
						toolTipDesc={"Find IATA codes by entering a city name."}
						regex={/^[a-zA-Z0-9\s]*$/}
					/>
				</div>
				<div className="lg:flex lg:pl-10 align-center">
					<UtilityClass.InputTable
						autoComplete={false}
						indexBool={!windowLarge}
						showIndex={Array(packageLevel === "Basic" ? 10 : 20).fill(true)}
						checkboxSmallerBottomMarginBool={true}
						rowNum={packageLevel === "Basic" ? 10 : 20}
						colNum={2}
						captionBool={true}
						label="GCD+X%"
						placeholder={["Route", "Diversion"]}
						dropdown={true}
						options={[
							["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
							["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
						]}
						internalTitle={["Route", "Diversion"]}
						value={secondTable}
						includeDefaults={defaultGCD}
						defaultVals={gcdInputs}
						toolTip={true}
						toolTipDesc={
							"The great-circle distance is the shortest distance between two points on the surface of a sphere."
						}
						width="auto"
						dropdownKey="gcd"
					/>
					<div className={`${!windowLarge ? "ml-24" : ""}`}>
						<UtilityClass.InputTable captionBool={true} label={"OR"} />
					</div>
					<UtilityClass.InputTable
						autoComplete={false}
						indexBool={!windowLarge}
						showIndex={Array(packageLevel === "Basic" ? 10 : 20).fill(true)}
						checkboxSmallerBottomMarginBool={true}
						rowNum={packageLevel === "Basic" ? 10 : 20}
						colNum={2}
						captionBool={true}
						label={"Custom Distance"}
						placeholder={["Route", "Diversion"]}
						value={setThird}
						includeDefaults={defaultDistance}
						defaultVals={cDistance}
						width="full"
						dropdownKey={defaultDistance ? "gcd" : "no"}
						regex={/^(?!0+$)\d*$/}
					/>
				</div>
			</div>
			<UtilityClass.BackNextButtons
				nextButtonText="NEXT"
				backLink={
					inputChoice === "Yes, I will provide custom values."
						? "/forms/" + packageType + "/CustomConfigWeights"
						: "/forms/" + packageType + "/AircraftEngine"
				}
				nextLink={"/forms/" + packageType + "/EnvironmentalConditions"}
				dispatchInputs={dispatchInputs}
				validation={validateContinue()}
				validationData
			/>
		</motion.div>
	);
};

export default RoutesAndDistances;
