import React from "react";

const Footer = () => {
	return (
		<footer className="absolute bottom-0 left-0 w-full p-4 mt-4 bg-white border-t border-gray-300 shadow flex items-center justify-center md:p-4">
			{process.env.REACT_APP_DEPLOYMENT !== "avpforms" && (
				<button className="absolute left-2" variant="outline-dark">
					<a className="text-sm" href="https://avptools.com">
						&larr;&nbsp;&nbsp;&nbsp;AVPTools
					</a>
				</button>
			)}
			<span className="text-sm text-black select-none cursor-default">
				© 2024{" "}
				<a
					href="https://aviaproconsulting.ca"
					target="_blank"
					rel="noopener noreferrer"
					className="hover:underline"
				>
					AviAnalysis by AviaPro Consulting Inc.{" "}
				</a>
				All Rights Reserved.
			</span>
			<div className="absolute right-4">
				<span className="pr-2 text-sm text-black select-none cursor-default">
					{process.env.REACT_APP_DEPLOYMENT === "avpforms"
						? "VERSION: 0.0.9.6"
						: "DEV VERSION: 0.0.9.6"}
				</span>
				{process.env.REACT_APP_DEPLOYMENT !== "avpforms" && (
					<span className="text-sm text-black select-none cursor-default">
						{`(04/12/2024)`}
					</span>
				)}
			</div>
		</footer>
	);
};

export default Footer;
