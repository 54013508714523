import React, { useState } from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useSelector, useDispatch } from "react-redux";
import { setSchedule } from "../../reducers/demandDataSlice";
import { motion } from "framer-motion";

const Schedule = (props) => {
	const dispatch = useDispatch();

	// redux calls
	let packageLevel = useSelector((state) => state.packageLevel.value);
	let markets = useSelector((state) => state.demandData.markets);
	let schedule = useSelector((state) => state.demandData.schedule);

	// check screen width
	var windowWidth = window.innerWidth;
	const [windowLarge, setWindowLarge] = useState(windowWidth >= 1024);
	const [windowXL, setWindowXL] = useState(windowWidth > 1441);
	window.onresize = handleResize;
	function handleResize() {
		windowWidth = window.innerWidth;
		// console.log(windowWidth);
		if (windowWidth < 1024) {
			setWindowLarge(false);
			setWindowXL(false);
		} else if (windowWidth <= 1441) {
			setWindowLarge(true);
			setWindowXL(false);
		} else {
			setWindowLarge(true);
			setWindowXL(true);
		}
	}

	let tempSchedule = [
		Array(5).fill(Array(3).fill("")),
		Array(5).fill(Array(7).fill("")),
		Array(5 * 3).fill(Array(4).fill("")),
	];

	// console.log(schedule[1], '<---- sched here')

	const breakArray = (arr, chunkSize) => {
		const out = [];
		for (let i = 0; i < arr.length; i += chunkSize) {
			const chunk = arr.slice(i, i + chunkSize);
			out.push(chunk);
		}
		return out;
	};

	const [marketInputs, setMarketInputs] = useState();
	let tempMarkets = [];
	const marketTableFunction = (type) => {
		tempMarkets.push(type);
		setMarketInputs(type);
	};

	const [opDaysInputs, setOpDaysInputs] = useState();
	let tempOpDays = [];
	const opDaysTableFunction = (type) => {
		tempOpDays.push(type);
		setOpDaysInputs(type);
		// console.log(opDaysInputs);
	};

	const [rotationsInputs, setRotationsInputs] = useState();
	let tempRotations = [];
	const rotationsTableFunction = (type) => {
		tempRotations.push(type);
		setRotationsInputs(type);
	};

	const dispatchInputs = () => {
		// markets table
		const arr1 = breakArray(tempMarkets, 3);
		tempSchedule[0] = [
			[markets[0][0], markets[0][1], arr1[0][2].toUpperCase()],
			[markets[1][0], markets[1][1], arr1[1][2].toUpperCase()],
			[markets[2][0], markets[2][1], arr1[2][2].toUpperCase()],
			[markets[3][0], markets[3][1], arr1[3][2].toUpperCase()],
			[markets[4][0], markets[4][1], arr1[4][2].toUpperCase()],
		];

		// operating days table
		let arr2 = breakArray(tempOpDays, 7);
		arr2 = arr2.map((subArray) =>
			subArray.map((item) => (item === false ? "" : item))
		);
		console.log(arr2);
		tempSchedule[1] = arr2;

		// rotations table
		const arr3 = breakArray(tempRotations, 4);
		tempSchedule[2] = arr3;

		dispatch(setSchedule(tempSchedule));
		console.log(tempSchedule);
	};

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.1 }}
		>
			<UtilityClass.CustomText
				textSize="subtitle"
				fontWeight="medium"
				bottomMargin={false}
				color="black"
				content={`${packageLevel} Level Analysis`}
			></UtilityClass.CustomText>
			<UtilityClass.CustomText
				textSize="large"
				fontWeight="medium"
				bottomMargin={true}
				color="black"
				content={`Forecast O&D traffic + market share based on ${
					packageLevel === "Basic" ? "your provided" : "an optimized"
				} schedule`}
			></UtilityClass.CustomText>
			<UtilityClass.Separator />
			<UtilityClass.CustomText
				textSize="large"
				fontWeight="medium"
				bottomMargin={true}
				color="black"
				content={`Input your schedule information with option for up to 3 rotations per day\nInput up to 5 markets using valid IATA codes\nFor each market, bi-directional route data will be provided`}
			></UtilityClass.CustomText>
			<div className="flex justify-center">
				<div
					id="inputTablesContainerDiv"
					className="justify-center lg:flex lg:w-11/12"
				>
					<UtilityClass.InputTable
						indexBool={true}
						indexBlack={true}
						rowNum={5}
						colNum={3}
						rowBottomMarginBool={windowLarge}
						headerLabelsBool={true}
						labelGray={true}
						redTextInLabelList={[false, true, true, true, false]}
						conditionalLabelsList={[
							"Market",
							"From\nIATA code",
							"To\nIATA code",
							"Partner Airlines\nIATA code",
						]}
						disabledBoolList={[true, true, false]}
						labelBottomMarginBoolList={[windowLarge, windowLarge, windowLarge]}
						showIndex={Array(5).fill(true)}
						includeDefaults={true}
						defaultVals={schedule[0]}
						value={marketTableFunction}
						width="full"
						capitalize={true}
						regex={[null, null, /^[a-zA-Z,]*$/]}
						multiRegex={true}
					></UtilityClass.InputTable>
					<UtilityClass.InputTable
						checkboxes={true}
						label={`Operating Days\n(7 days of the week)`}
						checkboxLabelBottomMarginBool={windowLarge}
						redTextInLabel={true}
						checkboxText={["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"]}
						verticalLabels="col"
						rowNum={5}
						colNum={7}
						checkboxesBottomMarginBool={windowLarge}
						indexBool={!windowLarge}
						indexBlack={!windowLarge}
						headerLabelsBool={!windowLarge}
						conditionalLabelsList={["Market"]}
						firstRowBottom={!windowLarge}
						showIndex={Array(5).fill(true)}
						includeDefaults={true}
						defaultVals={schedule[1]}
						value={opDaysTableFunction}
						width={"full"}
					></UtilityClass.InputTable>
					<UtilityClass.InputTable
						rowNum={3 * 5}
						colNum={4}
						headerLabelsBool={true}
						conditionalLabelsList={[
							"Market",
							"Departure\nLocal Time",
							"Arrival\nLocal Time",
							"Departure\nLocal Time",
							"Arrival\nLocal Time",
						]}
						labelRed={true}
						leftRedTextBool={true}
						leftRedTextList={[
							"Rotation 1",
							"Rotation 2",
							"Rotation 3",
							"Rotation 1",
							"Rotation 2",
							"Rotation 3",
							"Rotation 1",
							"Rotation 2",
							"Rotation 3",
							"Rotation 1",
							"Rotation 2",
							"Rotation 3",
							"Rotation 1",
							"Rotation 2",
							"Rotation 3",
						]}
						placeholder={["hh:mm", "hh:mm", "hh:mm", "hh:mm"]}
						dateTime={true}
						doubleFlexCaptionBool={true}
						doubleFlexCaptionLarge={windowLarge}
						doubleFlexCaptionFirstText={"Outbound:"}
						doubleFlexCaptionSecondText={"Inbound:"}
						rowConditionalMarginBoolList={[
							false,
							false,
							true,
							false,
							false,
							true,
							false,
							false,
							true,
							false,
							false,
							true,
							false,
							false,
							true,
						]}
						showIndex={[
							true,
							false,
							false,
							true,
							false,
							false,
							true,
							false,
							false,
							true,
							false,
							false,
							true,
							false,
							false,
						]}
						showIndexSkipped={true}
						indexBool={!windowLarge}
						indexBlack={!windowLarge}
						includeDefaults={true}
						defaultVals={schedule[2]}
						value={rotationsTableFunction}
						width="full"
					></UtilityClass.InputTable>
				</div>
			</div>
			<UtilityClass.BackNextButtons
				nextButtonText="NEXT"
				backLink={"/forms/Demand"}
				nextLink={"/forms/Demand/InputReview"}
				dispatchInputs={dispatchInputs}
				validation={true}
			></UtilityClass.BackNextButtons>
		</motion.div>
	);
};

export default Schedule;
