import React, { useEffect } from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
	selectCashOperatingCosts,
	selectFuelPriceData,
	selectLocation,
	selectRegion,
} from "../../reducers/econDataSlice";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

const CashOperatingCosts = (props) => {
	// This component is an extension of entering the economic numbers from FixedOperatingCost but can be called from 2 options
	// (1. CostAssumptions OR 2. Economic main component ** need to see what will be better** )

	let location = useLocation();

	let econData = useSelector((state) => state.econData);

	let prevLocation = econData.location;
	let prevOwnership = econData.aircraftOwnership2;
	let prevBasicOwnership = econData.aircraftOwnership1;
	// redux calls
	let packageType = useSelector((state) => state.packageType.value);
	let packageLevel = useSelector((state) => state.packageLevel.value);

	let fuelData = econData.cashOperatingCosts.fuelPrice;
	let fuelUnitData = econData.cashOperatingCosts.fuelPriceUnit;
	let regionList = econData.regionData;
	let currentRegion = econData.region;

	const [defaultRegion, setDefaultRegion] = useState(
		currentRegion === undefined || currentRegion.length === 0 ? false : true
	);

	const [defaultFuel, setDefaultFuel] = useState(
		fuelData === undefined ? false : true
	);
	const [defaultFuelUnit, setDefaultFuelUnit] = useState(
		fuelUnitData === undefined || fuelUnitData.length === 0 ? false : true
	);

	const [firstChoice, setFirstChoice] = useState(true);
	const [isChanged, setIsChanged] = useState(false);

	const dispatch = useDispatch();
	const [fuelPrice, setFuelPrice] = useState(fuelData);
	const chooseFuelPrice = (v) => {
		setFuelPrice(v);
	};

	const [region, setRegion] = useState(currentRegion);
	const chooseRegion = (v) => {
		if (firstChoice) {
			setFirstChoice(false);
		}
		setIsChanged(true);
		setRegion(v);
	};

	const [regionData, setRegionData] = useState(regionList);
	const chooseRegionData = (v) => {
		setRegionData(v);
	};

	const [fuelPriceUnit, setFuelPriceUnit] = useState(fuelUnitData);
	const chooseFuelPriceUnit = (v) => {
		setFuelPriceUnit(v);
	};

	const conversion = (num, iunit) => {
		if (iunit === "Per Litre") {
			return (num / 3.785412).toFixed(2);
		} else if (iunit === "Per US Gallon") {
			return (num * 3.785412).toFixed(2);
		} else {
			return num;
		}
	};

	const convertUnits = (unit) => {
		// setDefaultFuel(parseFloat(conversion(fuelData, fuelPriceUnit)));
		fuelData = handleDispatch(unit, 2).fuelPrice;
		// setFuelPrice(parseFloat(conversion(fuelData, fuelPriceUnit)));
	};
	// useEffect(() => {
	// 	if (fuelPriceUnit) {
	// 		convertUnits(fuelPriceUnit);
	// 		console.log(fuelData);
	// 		// console.log(handleDispatch(fuelPriceUnit, 2).fuelPrice);
	// 	}
	// }, [fuelPriceUnit]);

	const dispatchInputs = () => {
		dispatch(selectLocation(location));
		dispatch(selectRegion(region));
		dispatch(selectCashOperatingCosts(handleDispatch(fuelPriceUnit, 1)));
		dispatch(selectFuelPriceData(handleDispatch(fuelPriceUnit, 4)));
	};

	const handleDispatch = (unit, link) => {
		switch (link) {
			case 1:
				return {
					fuelPrice: parseFloat(fuelPrice).toFixed(3),
					fuelPriceUnit: fuelPriceUnit,
				};
			case 2:
				return {
					fuelPrice:
						unit === "Per Litre"
							? fuelPrice === ""
								? parseFloat(fuelPrice).toFixed(3)
								: parseFloat(fuelPrice / 3.785412).toFixed(3)
							: fuelPrice === ""
							? parseFloat(fuelPrice).toFixed(3)
							: parseFloat(fuelPrice * 3.785412).toFixed(3),
					fuelPriceUnit: fuelPriceUnit,
				};
			case 3:
				if (region.length > 1) {
					let converted = Object.fromEntries(
						Object.entries(econData.fuelPriceData).map((value) => {
							if (unit === "Per Litre") {
								return [
									value[0],
									[
										[value[1][0][0] / 3.785412, value[1][0][1]],
										[value[1][1][0] / 3.785412, value[1][1][1]],
										[value[1][2][0] / 3.785412, value[1][0][1]],
									],
								];
							} else {
								return [
									value[0],
									[
										[value[1][0][0] * 3.785412, value[1][0][1]],
										[value[1][1][0] * 3.785412, value[1][1][1]],
										[value[1][2][0] * 3.785412, value[1][0][1]],
									],
								];
							}
						})
					);
					return converted;
				} else {
					break;
				}

			case 4:
				return econData.fuelPriceData;
			default:
				break;
		}
	};

	useEffect(() => {
		if (fuelPriceUnit) {
			dispatch(selectCashOperatingCosts(handleDispatch(fuelPriceUnit, 2)));
			dispatch(selectFuelPriceData(handleDispatch(fuelPriceUnit, 3)));
		}
	}, [fuelPriceUnit]);

	useEffect(() => {
		let initInputs = {
			fuelPrice: fuelData,
			fuelPriceUnit: fuelPriceUnit,
		};
		dispatch(selectCashOperatingCosts(initInputs));
		dispatch(selectFuelPriceData(econData.fuelPriceData));

		if (region) {
			setFirstChoice(false);
		}
	}, []);

	const validateContinue = () => {
		let validated = false;
		if (fuelPrice != "" && region != "") {
			validated = true;
		} else {
			validated = false;
		}
		return validated;
	};

	return (
		<motion.div>
			<UtilityClass.CustomText
				textSize="subtitle"
				fontWeight="semibold"
				color="black"
				content={"Cash Operating Costs"}
			/>
			<UtilityClass.Separator />
			<UtilityClass.CustomText
				textSize="medium"
				fontWeight="semibold"
				color="black"
				content={"Select a region for default cost assumptions:"}
			/>
			<div className="flex justify-center">
				<div className="w-full max-w-sm">
					<UtilityClass.DropdownMenu
						options={regionData[0].regions}
						internalTitle="Please select"
						value={chooseRegion}
						includeDefaults={defaultRegion}
						defaultVals={defaultRegion && region}
						defaultValue={region}
						width="1/2"
					></UtilityClass.DropdownMenu>
				</div>
			</div>
			<UtilityClass.Separator />
			<UtilityClass.CustomText
				textSize="subtitle"
				fontWeight="semibold"
				color="black"
				content={"Fuel Price"}
			/>
			<div className="flex mx-auto gap-4 justify-center align-middle w-full max-w-xs">
				<UtilityClass.DefaultInput
					rounded
					disabledBool={firstChoice}
					combinedDropdown={[
						true,
						{
							data: econData.fuelPriceData
								? econData.fuelPriceData[region]
								: "",

							header: ["Please select"],
						},
					]}
					leftSquareLabelBool={true}
					leftSquareLabelText={"USD"}
					isChanged={isChanged}
					setIsChanged={setIsChanged}
					placeholder={"Cost"}
					value={chooseFuelPrice}
					includeDefaults={defaultFuel}
					defaultVals={fuelData}
					defaultValue={region ? fuelData : ""}
					width="full"
					// regex={/^(0?|[1-9]\d{0,1})(\.\d{0,2})?$/}
					regex={/^(0?|[1-9]\d?)(\.\d{0,2})?$/}
				/>
				{/* ["5",[0,0]] */}
				<div className="my-auto">
					<UtilityClass.DropdownMenu
						disabledBool={[firstChoice, firstChoice]}
						options={["Per US Gallon", "Per Litre"]}
						internalTitle="Select Unit"
						value={chooseFuelPriceUnit}
						includeDefaults={defaultFuelUnit}
						defaultVals={defaultFuelUnit ? fuelUnitData : "Per US Gallon"}
						defaultValue={fuelUnitData}
						width="auto"
					></UtilityClass.DropdownMenu>
				</div>
			</div>

			<UtilityClass.BackNextButtons
				nextButtonText="NEXT"
				nextLink={"/forms/" + packageType + "/CrewLoading"}
				dispatchInputs={dispatchInputs}
				validation={validateContinue()}
			/>
		</motion.div>
	);
};

export default CashOperatingCosts;
